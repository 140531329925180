<template>
  <div>
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      <div>
        Счета ({{ accounts.length }})
        <i v-if="loading" class="el-icon-loading"></i>
      </div>
      <div class="flex items-center justify-end">
        <el-input
          v-model="name"
          class="mr-2"
          placeholder="Название счета"
        ></el-input>
        <el-button @click="create">Создать счет</el-button>
      </div>
    </h1>
    <el-table border size="medium" :data="accounts">
      <el-table-column prop="name" label="Название"></el-table-column>
      <el-table-column prop="balance" label="Баланс"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { createAccount, getAccounts } from "@/api/transactions";

export default {
  name: "accounts",
  data() {
    return {
      name: "",
      loading: false,
      accounts: [],
    };
  },
  async mounted() {
    await this.getAccounts();
  },
  methods: {
    async getAccounts() {
      this.loading = true;
      this.accounts = await getAccounts();
      this.loading = false;
    },
    async create() {
      if (this.name.trim() === "") {
        return;
      }
      await createAccount(this.name);
      this.name = "";
      await this.getAccounts();
    },
  },
};
</script>

<style scoped></style>
