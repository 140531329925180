<template>
  <div>
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      <div>
        Категории ({{ categories.length }})
        <i v-if="loading" class="el-icon-loading"></i>
      </div>
      <div class="flex items-center justify-end">
        <el-input
          v-model="name"
          class="mr-2"
          placeholder="Название категории"
        ></el-input>
        <div class="mr-2">
          <el-checkbox v-model="is_delivery" label="Доставка"></el-checkbox>
        </div>
        <el-button @click="create">Создать</el-button>
      </div>
    </h1>
    <el-table border size="medium" :data="categories">
      <el-table-column prop="name" label="Название"></el-table-column>
      <el-table-column prop="is_delivery" label="Доставка">
        <template v-slot="scope">
          <i v-if="scope.row.is_delivery" class="el-icon-circle-check"></i>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { createCategory, getCategories } from "@/api/transactions";

export default {
  name: "accounts",
  data() {
    return {
      name: "",
      is_delivery: false,
      loading: false,
      categories: [],
    };
  },
  async mounted() {
    await this.getCategories();
  },
  methods: {
    async getCategories() {
      this.loading = true;
      this.categories = await getCategories();
      this.loading = false;
    },
    async create() {
      if (this.name.trim() === "") {
        return;
      }
      await createCategory(this.name, this.is_delivery);
      this.name = "";
      await this.getCategories();
    },
  },
};
</script>

<style scoped></style>
